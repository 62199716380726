<template>
  <div>
    <label style="display: inline-block">
      <div class="add-image">
        {{ `${labels.LBL_ADD} ${labels.LBL_IMAGES}` }}
      </div>
      <input
        type="file"
        accept="image/*"
        multiple
        style="display: none"
        @change="handleChange"
      />
    </label>

    <div
      v-for="(image, index) in imageList"
      :key="`img-${index}`"
      class="media-card"
    >
      <img
        v-if="image.poster"
        :src="createPosterUrl(image.poster)"
        alt="Image Thumb"
      />
      <div class="media-card__info">
        <el-input v-if="!disableInput" v-model="image.title"></el-input>
        <p v-else>{{ image.title }}</p>
      </div>
      <div class="action-buttons">
        <i class="el-icon-close" @click="removeImage(index, image)"></i>
      </div>
    </div>
  </div>
</template>

<script>
  import { labels } from "@/common";

  export default {
    name: "AddImage",

    components: {},

    props: {
      images: {
        type: Array,
        default() {
          return [];
        },
      },

      update: {
        type: Boolean,
        default: false,
      },

      disableInput: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        labels,
        image: {
          title: "",
          poster: null,
        },
        imageList: [],
      };
    },

    watch: {
      images: {
        immediate: true,
        handler(nv) {
          this.imageList = nv;
        },
      },

      imageList: {
        immediate: true,
        handler(nv) {
          if (nv) this.$emit("onImageAdd", this.imageList);
        },
        deep: true,
      },
    },

    methods: {
      handleChange(e) {
        const fileList = [...e.target.files];
        const list = fileList.map((f) => ({
          title: f.name
            .split(".")
            .slice(0, -1)
            .join("."),
          poster: f,
        }));
        this.imageList = [...this.imageList, ...list];
      },

      createPosterUrl(poster) {
        if (typeof poster.name === "string") {
          const url = URL.createObjectURL(poster);
          return url;
        }
        return poster;
      },

      removeImage(index, image) {
        this.$confirm(`${labels.CONF_DELETE}?`, labels.LBL_WARN, {
          confirmButtonText: labels.LBL_YES,
          cancelButtonText: labels.LBL_CANCEL,
          type: labels.LBL_WARN.toLowerCase(),
        })
          .then(() => {
            if (this.update && image.created_at)
              this.$store.dispatch("album/deleteMedia", image.id).then(() => {
                this.imageList.splice(index, 1);
              });
            else this.imageList.splice(index, 1);
          })
          .catch(() => {
            this.$message({
              type: labels.LBL_INFO.toLowerCase(),
              message: labels.CNCL_DELETE,
            });
          });
      },
    },
  };
</script>

<style lang="scss">
  .media-card {
    position: relative;
    margin: 0.5rem 0;
    border: 1px solid lightgray;
    border-radius: 0.35rem;
    background: white;
    height: 75px;

    padding: 0.5rem;
    display: flex;
    align-items: center;
    overflow: hidden;

    img {
      height: 100%;
      min-width: 75px;
      object-fit: cover;
      margin-right: 0.5rem;
    }

    &__info {
      padding: 0.5rem;
      width: 100%;
    }
  }

  .action-buttons {
    position: absolute;
    display: inline-block;
    top: 5px;
    right: 5px;
    cursor: pointer;

    i {
      padding: 0.25rem;
      border-radius: 1rem;
      background: white;
      border: 1px solid lightgray;
      margin-left: 0.25rem;
    }

    i:hover {
      background: lightgray;
    }
  }

  .add-image {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    cursor: pointer;
  }
</style>
