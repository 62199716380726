<template>
  <div>
    <el-button size="small" type="primary" @click="openDialog">
      {{ `${labels.LBL_ADD} ${labels.LBL_VIDEOS}` }}
    </el-button>

    <el-dialog :title="labels.LBL_ADD_VID" :visible.sync="dialog">
      <el-form ref="videoForm" :model="video" :rules="rules">
        <el-row :gutter="20" type="flex">
          <el-col :span="12">
            <el-form-item label="Thumbnail">
              <UploadImage v-if="dialog" v-model="video.poster" />
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item prop="title" :label="labels.LBL_TITLE">
              <el-input v-model="video.title"></el-input>
            </el-form-item>
            <el-form-item prop="url" :label="labels.LBL_URL">
              <el-input v-model="video.url"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeDialog">
          {{ labels.LBL_CANCEL }}
        </el-button>

        <el-button size="mini" type="primary" @click="submitForm('videoForm')">
          {{ edit ? labels.LBL_UPDATE : labels.LBL_ADD }}
        </el-button>
      </span>
    </el-dialog>

    <div
      v-for="(video, index) in videoList"
      :key="video.title"
      class="media-card"
    >
      <img
        v-if="video.poster"
        :src="createPosterUrl(video.poster)"
        alt="Video Thumb"
      />

      <div class="media-card__info">
        <div>Title: {{ video.title }}</div>
        <p class="url">
          {{ `${labels.LBL_URL}:` }} <span> {{ video.url }}</span>
        </p>
      </div>

      <div class="action-buttons">
        <i class="el-icon-edit" @click="editVideo(video)"></i>
        <i class="el-icon-close" @click="removeVideo(index, video)"></i>
      </div>
    </div>
  </div>
</template>

<script>
  import { labels, rules } from "@/common";
  import UploadImage from "@/components/UploadImage";

  export default {
    name: "AddVideo",

    components: {
      UploadImage,
    },

    props: {
      videos: {
        type: Array,
        default() {
          return [];
        },
      },

      update: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        labels,
        rules,
        dialog: false,
        video: {
          title: "",
          url: "",
          poster: null,
        },
        videoList: [],
        edit: false,
      };
    },

    watch: {
      videos: {
        immediate: true,
        handler(nv) {
          this.videoList = nv;
        },
      },

      videoList: {
        handler(nv) {
          if (nv) this.$emit("onVideoAdd", this.videoList);
        },
        deep: true,
      },
    },

    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.edit) {
              const updatedVideo = { ...this.video };
              this.updateList(updatedVideo);
            } else {
              this.addVideo();
            }
            this.closeDialog();
          }
        });
      },

      openDialog() {
        this.video.poster = null;
        this.dialog = true;
      },

      closeDialog() {
        this.video = {
          title: "",
          url: "",
          poster: null,
        };
        this.dialog = false;
        this.edit = false;
      },

      createPosterUrl(poster) {
        if (typeof poster.name === "string") {
          const url = URL.createObjectURL(poster);
          return url;
        }
        return poster;
      },

      addVideo() {
        this.video.id = Math.random() * 0x10000;
        this.videoList = [...this.videoList, this.video];
      },

      removeVideo(index, video) {
        this.$confirm(`${labels.CONF_DELETE}?`, labels.LBL_WARN, {
          confirmButtonText: labels.LBL_YES,
          cancelButtonText: labels.LBL_CANCEL,
          type: labels.LBL_WARN.toLowerCase(),
        })
          .then(() => {
            if (this.update && video.created_at)
              this.$store.dispatch("album/deleteMedia", video.id).then(() => {
                this.videoList.splice(index, 1);
              });
            else this.videoList.splice(index, 1);
          })
          .catch(() => {
            this.$message({
              type: labels.LBL_INFO.toLowerCase(),
              message: labels.CNCL_DELETE,
            });
          });
      },

      editVideo(video) {
        this.dialog = true;
        this.edit = true;
        this.video = video;
        if (typeof video.poster.name === "string")
          this.video.poster = this.createPosterUrl(video.poster);
      },

      updateList(vid) {
        this.videoList.map((v) => (v.id === vid.id ? vid : v));
        this.edit = false;
      },
    },
  };
</script>

<style lang="scss">
  @import "@/assets/scss/main";

  .media-card {
    position: relative;
    margin: 0.5rem 0;
    border: 1px solid lightgray;
    border-radius: 0.35rem;
    background: white;
    height: 75px;

    padding: 0.5rem;
    display: flex;
    align-items: center;
    overflow: hidden;

    img {
      height: 100%;
      width: 75px;
      object-fit: cover;
      margin-right: 0.5rem;
    }

    &__info {
      width: 100%;
      .url {
        width: 300px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .action-buttons {
    position: absolute;
    display: inline-block;
    top: 2px;
    right: 5px;
    cursor: pointer;

    i {
      padding: 0.25rem;
      border-radius: 1rem;
    }

    i:hover {
      background: lightgray;
    }
  }
</style>
